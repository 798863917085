<template>
  <div class="mb-n3 mb-md-n5" :class="{'h-100': forceFullSizeSlide}">
    <slot name="before">
      <!--content before sticky goes here (sticky will be stuck to bottom of viewport) -->
    </slot>
    <div v-show="stickies" class="my-sticky-bottom mt-3 mt-md-5 mx-n3 mx-md-n5 sticky-shadow"
        :class="{'shadow-sm': !isSticky}">
      <template v-for="sticky in stickies">
        <BallotStatus
            v-if="sticky.type === 'ballotStatus'"
            :voted="sticky.voted"
            :can-vote="sticky.canVote"
            :ballot-slide="sticky.ballot"
        />
        <ContestStatus
            v-else-if="sticky.type === 'contestStatus'"
            :contest="sticky.contest"
            :active-selection-pile="sticky.activeSelectionPile"
        />
        <div v-else class="text-sm-center px-3 py-2 bg-theme" :class="sticky.class">
          <span class="sub-lead">{{ sticky.message }}</span>
        </div>
      </template>
    </div>
    <a id="sticky-anchor" ref="stickyAnchor"></a>
    <slot name="after">
      <!-- content after sticky goes here (sticky will scroll out of view with the content in "before" slot -->
    </slot>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BallotStatus from "./sticky_alerts/BallotStatus.vue";
import ContestStatus from "./sticky_alerts/ContestStatus.vue";

export default {
  name: "StickyAlerts",
  components: {
    BallotStatus,
    ContestStatus,
  },
  data() {
    return {
      subscribers: [],
      isSticky: false
    }
  },
  methods: {
    ...mapActions(['updateStatus']),
    ...mapGetters(['forceFullSizeSlide']),
    stickyAlertsSubscribe(component) {
      this.subscribers.push(component)
    },
    stickyAlertsUnsubscribe(component) {
      const subscriberIndex = this.subscribers.findIndex(subscriber => subscriber === component);
      this.subscribers.splice(subscriberIndex, 1);
    },
  },
  computed: {
    stickies() {
      let alerts = []
      this.subscribers.forEach(subscriber => subscriber.stickies.forEach(alert => alerts.push(alert)))
      return alerts
    }
  },
  provide: function () {
    return {
      stickyAlertsSubscribe: this.stickyAlertsSubscribe,
      stickyAlertsUnsubscribe: this.stickyAlertsUnsubscribe,
    }
  },
  mounted: function () {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.0
    }
    let component = this;
    new IntersectionObserver(function (entries, observer) {
      component.isSticky = !entries[0].isIntersecting
    }, options).observe(this.$refs.stickyAnchor);
  }
}

</script>